<template>
    <div id='agreementWrapper' dir='rtl'>
        <div>
            <p>המחקר הזה נועד כדי שנוכל ללמוד טוב יותר על הגורמים שיכולים להשפיע על מהלך המחלה ויכולת הריפוי ממנה. מהידע שקיים עד היום על המחלה ברור שהנזק שנגרם הוא כתוצאה משילוב של הנזק הישיר שנגרם על ידי הנגיף עם התגובה של המערכת החיסונית אליו. הבנה טובה יותר של הפעילות של המערכת החיסונית של חולים שהחלימו מקורונה יכולה לסייע לנו למצוא טיפול טוב יותר למחלה ששיתקה את כלכלת העולם. בנוסף, המחקר יאפשר לנו ללמוד טוב יותר על פעילות המערכת החיסונית והאם ייתכן שפגם קל ובלתי מזוהה משפיע על חומרת המחלה.</p>
            <p>השאלון נמצא באתר לא מאובטח למידע רפואי ולכן אנחנו לא מבקשים ממך פרטים מזהים.</p>
            <p><b>זכויותיך כמשתתף במחקר:</b> זכותך לענות או לא לענות על כל שאלה שתבחר, אתה רשאי גם לפנות אלינו בכל שאלה שתתעורר, אך לא במצבי חירום שכן זמן המענה שלנו הוא 48 תוך שעות. זכותך גם להחליט להפסיק את השתתפותך במחקר בכל עת. אתה רשאי לדלג על שאלות שגורמות לך אי נוחות.</p>
            <p><b>התועלת הצפויה:</b> המידע שיצטבר מהנתונים שלך ושל חולים אחרים, יכול לסייע בהבנה טובה יותר של מחלת הקורונה ואולי גם לאפשר לנו לזהות חולים שיש להם בעייה לא מאובחנת בפעילות המערכת החיסונית. בנוסף, במידה ותהיה מעוניין בכך תוכל לפנות לד"ר הרן ולקבוע פגישה במרפאה ההמטולוגית בקפלן כדי לקבל את תוצאות הניתוח של השאלון האישי שלך והמלצות (אם ישנן) להמשך בירור או טיפול.</p>
            <p><b>יצירת קשר:</b> תוכל ליצור קשר עם ד"ר מיכל הרן - michal.haran@gmail.com
                או בטלפון/וואטסאפ במספר <span class='phone'>051-5597139</span>.</p>
            <p><b>שימוש במידע:</b> המידע שייאסף ינותח על ידי מומחי תוכנה, ללא פרטים מזהים. במידה ותהיה מעוניין תוכל לקבל אינפורמאציה נוספת על אופן ניתוח המידע בשיטות של למידת מכונה.</p>
        </div>
        <div id='share' :class='{ error: !info.valid.share }'>
            <p>אנחנו מבקשים גם את רשותך להוציא פרטים נוספים מהתיק הרפואי שלך (אבחנות, תוצאות של בדיקות, תרופות וכד). אתה רשאי לא להסכים וזה אינו מהווה תנאי להשתתפותך במחקר.</p>
            <label multi-choice>
                <input type='radio' name='info[radio]' @click='setShare()' agreed-to-share />
                אני מסכים לכך ש דר' הרן תתקשר אלי לקבל ממני את שמי המלא ומספר תעודת הזהות שלי. אני מבין שזה נחוץ כדי שניתן יהיה להוציא את הפרטים מהתיק הרפואי שלי. אני מבין כי פרטים אלו יישמרו במסמך חסוי במחשב של כללית.
            </label>
            <br v-if='false'/>
            <label multi-choice>
                <input type='radio' name='info[radio]' @click='setShare(false)' did-not-agree-to-share />
                אני מעדיף שלא למסור פרטים מזהים.
            </label>
            <input type='hidden' name='info[share]' v-model='info.share' />
            <div class='agreementErrorMsg'>
                יש לבחור אחת מהאפשרויות כדי להמשיך
            </div>
        </div>
        <div id='readAgreed' :class='{ error: !info.valid.read || !info.valid.agreed }'>
            <p>במידה ואתה מעוניין להשתתף במחקר, עליך לסמן שאתה מסכים לסעיפים הבאים:</p>
            <label multi-choice>
                <input type='checkbox' name='info[read]' v-model='info.read' @click='reset("read")' pdf-checked />
                קראתי את הכתוב למעלה והבנתי שמדובר במחקר שנועד לבדוק מה הגורמים שמשפיעים על המהלך של מחלת הקורונה וגם לבדוק האם ניתן לזהות בעייה קלה ולא מזוהה במערכת החיסונית בעזרת שאלון פשוט. אני מבין, שבמידה ואהיה מעוניין, אוכל לפנות לצוות המחקר בכדי לקבל אינפורמציה נוספת.
            </label>
            <br v-if='false'/>
            <label multi-choice>
                <input type='checkbox' name='info[agreed]' v-model='info.agreed' @click='reset("agreed")' pdf-checked />
                אני מביע את רצוני והסכמתי להשתתף במחקר ומבין שמותר לי להשתתף בו וכן מותר לי להפסיק את השתתפותי בכל עת.
            </label>
            <div class='agreementErrorMsg'>
                לא ניתן להמשיך מבלי להביע הסכמה לשני הסעיפים
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from "@vue/runtime-core";
    import { StudyState } from '@/ts/state/StudyState';

    export default defineComponent({
        computed: {
            info(){
                return this.$store.state.study!.agreement;
            }
        },
        methods: {
            setShare(share = true){
                this.info.share = share;
                this.reset('share');
            },
            reset(field: keyof StudyState['agreement']['valid']){
                this.info.valid[field] = true;
            }
        }
    });
</script>
