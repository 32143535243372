
    import { defineComponent } from "@vue/runtime-core";
    import { StudyState } from '@/ts/state/StudyState';

    export default defineComponent({
        computed: {
            info(){
                return this.$store.state.study!.agreement;
            }
        },
        methods: {
            setShare(share = true){
                this.info.share = share;
                this.reset('share');
            },
            reset(field: keyof StudyState['agreement']['valid']){
                this.info.valid[field] = true;
            }
        }
    });
