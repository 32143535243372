
    import { defineComponent } from '@vue/runtime-core';
    import agreement from './agreement.vue';
    import { agreementInvalid } from '../module'
    import { updateSubject } from '../helpers';

    export default defineComponent({
        components: { agreement },
        computed: {
            agreement(){
                return this.$store.state.study?.agreement;
            }
        },
        methods: {
            next() {
                let { share, read, agreed, valid } = this.$store.state.study!.agreement!;
                if(share === null)
                    agreementInvalid('share');
                if(!read)
                    agreementInvalid('read');
                if(!agreed)
                    agreementInvalid('agreed');
                if(share === null || !read || !agreed){
                    const elem = !valid.share ? 'share' : 'readAgreed';
                    const elemPos = (document.querySelector(`#${elem}`) as HTMLElement).offsetTop;
                    return document.querySelector('main')?.scrollTo({ top: (elemPos as number) - 28, behavior: 'smooth' });
                }

                const updates = { agreedAt: Date.now(), agreedToShare: share };
                updateSubject(updates);
                this.$router.push(`demographics`);
            },
        }
    });
