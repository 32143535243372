<template>
    <section id='agreement'>
        <div class='parent'>
            <h1>תודה שהסכמת להשתתף במחקר</h1>
            <agreement :info='agreement'></agreement>
            <button study-button @click='next()' type='button'>המשך</button>
        </div>
    </section>
</template>

<script lang="ts">
    import { defineComponent } from '@vue/runtime-core';
    import agreement from './agreement.vue';
    import { agreementInvalid } from '../module'
    import { updateSubject } from '../helpers';

    export default defineComponent({
        components: { agreement },
        computed: {
            agreement(){
                return this.$store.state.study?.agreement;
            }
        },
        methods: {
            next() {
                let { share, read, agreed, valid } = this.$store.state.study!.agreement!;
                if(share === null)
                    agreementInvalid('share');
                if(!read)
                    agreementInvalid('read');
                if(!agreed)
                    agreementInvalid('agreed');
                if(share === null || !read || !agreed){
                    const elem = !valid.share ? 'share' : 'readAgreed';
                    const elemPos = (document.querySelector(`#${elem}`) as HTMLElement).offsetTop;
                    return document.querySelector('main')?.scrollTo({ top: (elemPos as number) - 28, behavior: 'smooth' });
                }

                const updates = { agreedAt: Date.now(), agreedToShare: share };
                updateSubject(updates);
                this.$router.push(`demographics`);
            },
        }
    });
</script>

<style lang="scss">
#agreement {
    /*FOR STUDY!!*/
    & .agreementErrorMsg {
        display: none;
    }

    & .error {
        border: none !important;
        
        & .agreementErrorMsg {
            border-radius: $corner;
            background-color: $hoverB;
            display: block;
            width: fit-content;
            margin: 1rem auto;
            padding: 1rem 2rem;
            text-align: center;
        }
    }

    & button {
        margin-top: auto;
        background-color: $swatchC;
        border-radius: 0;
        font-size: 1.1rem;
        font-weight: 500;
        padding: 1rem;

        &:hover {
            background-color: $hoverC;
        }
    }

    & .parent {
        max-width: 54rem;
        min-height: 100vh;
        justify-content: flex-start;
        & > div {
            padding: 0 2rem;
            margin-bottom: 2rem;
            text-align: justify;
        }

        & label {
            padding-right: 1.5rem;
            text-indent: -1.75rem;
            cursor: pointer;
        }
    }

    & .parent, h1 {
        border-radius: 0;
        margin: 0 !important;
        width: 100%;
    }
}

@media only screen and (max-width: 768px){
    #agreement {
        width: 100%;
        margin: 0;

        & .parent {
            background-color: rgba(#fff, 0.7);
            padding-bottom: 4rem;
        }

        & button {
            width: 90%;
            margin: 1rem auto 0;
            border-radius: $corner;
        }
    }
}
</style>
